<template>
  <div class="customer-service p0 relative">
    <span class="background-color" />
    <div class="container flex column">
      <h2 class="center-md center-xs title fs-big cl-black weight-400">
        {{ $t('Customer service') }}
      </h2>
      <div class="row center-md m0">
        <div v-for="item in customerService" :key="item.alt" class="relative customer-service__section col-md-5 col-xs-12">
          <img class="image-card pb30" :alt="item.alt" v-lazy="item.image" width="646" height="431">
          <div class="customer-service-card">
            <h2 class="customer-service-card__header">
              {{ $t(item.headText) }}
            </h2>
            <p class="customer-service-card__paragraph">
              {{ $t(item.paragraphText) }}
            </p>
            <button-outline v-if="!item.link" class="customer-service-card__button" :link="item.link" :title="item.buttonText">
              <a class="customer-service-card__href" href="https://www.google.pl/maps/dir//E-Garderobe,+Mokotowska+45,+00-551+Warszawa/@52.2231692,20.9848274,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x471ecd23d60895b7:0x8022ea5f5aa2699!2m2!1d21.0199325!2d52.2231218" target="_blank" rel="noopener noreferrer">{{ $t('Show direction') }}</a>
            </button-outline>
            <button-outline v-if="item.link" class="customer-service-card__button" :link="item.link" :title="item.buttonText" />
          </div>
        </div>
      </div>
      <h2 class="center-md center-xs title2 fs-big cl-black weight-400 m0">
        {{ $t('We have already') }}
      </h2>
      <h2
        v-observe-visibility="{
          callback: visibilityChanged,
          once: true
        }"
        class="center-md center-xs number-big cl-black weight-400 m0"
      >
        {{ displayNumber }}
      </h2>
      <p class="center-md center-xs fs22 happy-clients-secondary__text cl-black weight-600 m0">
        {{ $t('HAPPY CUSTOMERS ') }}
      </p>
    </div>
  </div>
</template>

<script>
import ButtonOutline from 'src/themes/theme-egarderobe/components/theme/ButtonOutline.vue'

export default {
  name: 'CustomerService',
  components: {
    ButtonOutline
  },
  data () {
    return {
      displayNumber: 0,
      displayNumberEnd: 500000,
      customerService: [
        { image: '/assets/showroom1v2.jpg', alt: 'Gala', headText: 'Visit our boutique in Warsaw', paragraphText: 'Mon - Fri 11:00-19:00 Sat 12:00-16:00', buttonText: 'Show direction', link: '' },
        { image: '/assets/contact1v2.jpg', alt: 'Instyle', headText: 'Do you want to look fabulous?', paragraphText: 'We are here to pick a perfect outfit for you!', buttonText: this.$t('Contact with us'), link: '/i/' + this.$t('contact') }
      ]
    }
  },
  computed: {
    animatedNumber () {
      return this.tweenedNumber.toFixed(0)
    }
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        this.fadeOutNumber()
      }
    },
    fadeOutNumber () {
      if (this.displayNumber === this.displayNumberEnd) {
        return
      }
      let start = Date.now()
      let duration = 2000
      let interval = window.setInterval(() => {
        let passed = Date.now() - start
        let change = Math.pow(passed / duration, 1 / 8) * this.displayNumberEnd
        if (change >= this.displayNumberEnd) {
          this.displayNumber = this.displayNumberEnd
          return clearInterval(interval)
        } else {
          this.displayNumber = Math.floor(change)
        }
      }, 100);
    }
  },
  props: {
    ossasions: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .background-color {
    content: "";
    background-color: #f5f5f5;
    position: absolute;
    background-repeat: no-repeat;
    top: 170px;
    left: 0;
    right: 0;
    bottom: -300px;
    z-index: -1;
    background-position: top;
  }
  /deep/.VueCarousel-navigation-prev {
    padding: 0!important;
    left: 0!important;
    i {
      font-size: 45px;
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
        font-size: 35px;
        font-weight: 800;
      }
    }
  }

  /deep/.VueCarousel-navigation-next {
    padding: 0!important;
    i {
      font-size: 45px;
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
        font-size: 35px;
        font-weight: 800;
      }
    }
  }
  .customer-service-card {
    position: absolute;
    width: 65%;
    height: 31%;
    bottom: 17%;
    left: 7%;
    padding: 20px;
    padding-top: 0;
    background-color: white;
    @media (max-width: 1507px) {
      height: 36%;
    }
    @media (max-width: 767px) {
      padding: 20px;
      padding-bottom: 100px;
      padding-left: 0;
      padding-right: 0;
      background-color: white;
      position: static;
      width: 100%;
      height: auto;
    }
  }
  .reviews-section {
    @media (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .quotermarks {
    font-size: 60px;
    position: absolute;
    top: 10%;
    left: 8%;
  }
  .happy-clients {
    padding: 50px;
    padding-top: 30px;
    display: flex;
    height: 100%;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
  @media (max-width: 767px) {
    padding: 20px;
    padding-top: 10px;
    }
  }
  .happy-clients::-webkit-scrollbar {
    display: none;
  }
  .number-big {
    @media (max-width: 767px) {
      font-size: 90px;
    }
    font-size: 120px;
    margin: 0;
  }
  .happy-clients-card {
    display: flex;
    margin: 10px;
    text-align: center;
    box-shadow: 0 0 1em #f5f5f5;
    background-color: white;
    padding-top: 55px;
    height: 70%;
    align-items: flex-start;
    flex-direction: column;
    @media (max-width: 767px) {
      display: flex;
      margin: 10px;
      text-align: left;
      background-color: white;
      padding: 10px;
      padding-top: 40px;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
  .customer-service-card__header {
    font-size: 20px;
    text-align: left;
    font-weight: 500;
    @media (max-width: 1304px) {
      font-size: 1.5vw;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      text-transform: uppercase;
      font-family: 'Poppins';
    }
  }
  .happy-clients-card__header {
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    @media (max-width: 1507px) {
      font-size: 1.2vw;
      font-weight: 400
    }
    @media (max-width: 767px) {
      font-size: 12px;
      text-align: left;
      font-weight: 400
    }
  }
  .customer-service-card__paragraph {
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    @media (max-width: 1304px) {
      font-size: 1vw;
    }
    @media (max-width: 767px) {
      font-size: 10px;
      text-align: center;
    }
  }
  .image-card {
    width: 100%;
    height: 90%;
     @media (max-width: 767px) {
       height: auto;
       padding-bottom: 0;
     }
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
   @media (max-width: 767px) {
     padding-left: 0;
     padding-right: 0;
   }
  }
  .happy-clients-secondary__text {
    @media (max-width: 767px) {
     font-size: 16px;
   }
  }
  .title {
   margin-top: 40px;
   margin-bottom: 40px;
   @media (max-width: 767px) {
     font-size: 28px;
   }
 }
 .title2 {
   margin-top: 0px;
   margin-bottom: 20px;
   @media (max-width: 767px) {
     padding-top: 30px;
     font-size: 28px;
   }
 }
  .customer-service-card__button {
    position: absolute;
    left: 4.33%;
    box-shadow: none;
    background-color: #eae5df;
    bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 1304px) {
      font-size: 1vw;
      bottom: 10px;
    }
    @media (max-width: 767px) {
      box-shadow: none;
      position: absolute;
      left: 50%;
      font-size: 12px;
      bottom: 20px;
      width: 80%;
      text-align: center;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: #f0f0f0;
      color: rgb(0, 0, 0);
      border: none;
    }
  }
  .customer-service-card__button:hover {
    a {
      color: white;
    }
  }
</style>
