<template>
  <div class="container my30 designers relative flex column middle-sm">
    <h2 class="center-md center-xs title fs-big cl-black weight-400 ">
      {{ $t('Featured Desiginers') }}
    </h2>
    <!-- <no-ssr>
      <carousel
        class="carousel col-md-11"
        v-bind="sliderConfig"
        :infinite="true"
      >
        <Slide v-for="(item, index) in desiginers" v-if="item.include_in_menu" :key="index" class="">
          <router-link class="cl-black" :to="item.url_path" >
            <img class="image" :alt="item.name" :src="getThumbnailPath(item.image, 432, 650)" :srcset="getThumbnailPath(item.image, 432, 650)" width="200" height="200">
            <p class="desiginers-name">{{ item.name }}</p>
          </router-link>
        </Slide>
      </carousel>
    </no-ssr> -->
    <div class="designers">
      <div v-for="(item, index) in filterDesigners" :key="index" class="designers-item">
        <router-link class="cl-black" :to="localizedRoute(item.url_path)" >
          <img class="image" :alt="item.name" v-lazy="getThumbnailPath(item.image, 432, 650)" width="200" height="200">
          <p class="desiginers-name">{{ item.name }}</p>
        </router-link>
      </div>
    </div>
    <button-outline class="static-block__button" :link="link" :title="$t('Learn More')"/>
  </div>
</template>

<script>
import ButtonOutline from 'src/themes/theme-egarderobe/components/theme/ButtonOutline.vue'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import NoSSR from 'vue-no-ssr'

export default {
  name: 'FeaturedDesiginers',
  components: {
    ButtonOutline,
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR
  },
  data () {
    return {
      sliderConfig: {
        perPageCustom: [[1, 2], [320, 2], [768, 3], [992, 4], [1400, 6]],
        resistanceCoef: 400,
        paginationEnabled: true,
        touchDrag: true,
        navigationEnabled: false,
        navigationNextLabel: '<i class="material-icons cl-gray">keyboard_arrow_right</i>',
        navigationPrevLabel: '<i class="material-icons cl-gray">keyboard_arrow_left</i>'
      }
    }
  },
  props: {
    desiginers: {
      type: Array,
      default: () => {}
    },
    link: {
      type: String,
      reguired: true,
      default: '/'
    }
  },
  computed: {
    filterDesigners () {
      return this.desiginers.filter(item => item.include_in_menu)
    }
  },
  methods: {
    getThumbnailPath (image, widht, height) {
      return _thumbnailHelper(image, widht, height, 'product')
    }
  }
}
</script>

<style lang="scss" scoped>
 .designers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-flex-pack: start;
    justify-content: start;
    width: 100%;
    margin-top: 0;
    }
 }
 .designers::-webkit-scrollbar {
    display: none;
  }
 .title {
   margin-top: 0;
   margin-top: 40px;
   margin-bottom: 30px;
   @media (max-width: 767px) {
     font-size: 28px;
   }
 }
 .image {
   object-fit: cover;
 }
 .designers-item {
    padding: 10px;
    text-align: left;
    font-weight: 700;
    @media (max-width: 767px) {
      font-weight: 400;
    }
 }
 .designers-item:hover {
    transition: all 0.3s;
    cursor: pointer;
 }
 .desiginers-name {
   font-weight: 400;
   text-transform: uppercase;
   font-size: 17px;
 }
 .static-block__button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    @media (max-width: 767px) {
      background: white;
      font-size: 11px;
      color: black;
    }
  }
  .designers {
    padding-bottom: 30px;
    margin-bottom: 0;
    // padding-left: 65px;
    // padding-right: 59px;
    @media (max-width: 1500 ) {
      // padding-left: 5px;
      // padding-right: 5px;
    }
  }
  .carousel {
    margin-bottom: 50px;
  }
</style>
