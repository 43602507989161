<template>
  <div class="container order-mobile">
    <div class="row">
      <h2 class="center-xs col-xs-12 fs-big cl-black weight-500 mb30 mobile-header">
        {{ $t('Our offer') }}
      </h2>
      <div class="px0 col-xs-12 visible-xs our-offer-carousel">
        <no-ssr>
          <carousel
            v-bind="sliderConfig"
            @pageChange="setMuted"
            :infinite="true"
          >
            <slide
              v-for="card in cards"
              :key="card.alt"
            >
              <our-offer-card :card="card" />
            </slide>
          </carousel>
          <div slot="placeholder" class="col-md-12 bg-cl-secondary-gray image" />
        </no-ssr>
      </div>
      <div class="abonament-info col-xs-12 col-md-12">
        <div v-for="card in cards" :key="card.alt" class="col-md-4 col-xs-8 abonament-info-card">
          <our-offer-card :card="card" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import OurOfferCard from 'theme/components/core/blocks/Home/OurOfferCard.vue'
import { mapGetters } from 'vuex'
import config from 'config'

export default {
  name: 'OurOfferTabs',
  components: {
    OurOfferCard,
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR
  },
  data () {
    return {

      sliderConfig: {
        perPageCustom: [[1, 1], [450, 2], [768, 3], [992, 3], [1400, 4]],
        centerMode: true,
        touchDrag: true,
        minSwipeDistance: 150,
        paginationEnabled: true,
        paginationActiveColor: '#000000',
        paginationColor: '#CFCFCF',
        paginationPosition: 'bottom-overlay',
        navigationEnabled: true,
        navigationNextLabel: '<i class="icon-chevron-right" />',
        navigationPrevLabel: '<i class="icon-chevron-left" />'
      },
      currentPage: 0
    }
  },
  computed: {
    ...mapGetters({
      getMenuCategories: 'category-next/getMenuCategories'
    }),
    cards () {
      return [
        { link: this.localizedRoute(this.getClothesUrl), image: '/assets/oferta-wypozyczam.jpg', alt: 'image1', textMobile: 'Rent desginer dresses and clothing for a fraction of the retail price', textDesc: 'For once-off occasions — I am renting! Get a guilt-free access to designer fashion for a fraction of the retail price. You don’t have to worry about dry-cleaning and damage insurance either! Look amazing at your event.', header: 'I rent', buttonText: 'Browse collection', price: 'Starting from 80 zł per day' },
        { link: this.localizedRoute('selling'), image: '/assets/oferta-zarabiam.jpg', alt: 'icon2', textMobile: 'Wear more clothes without spending lots of money with our monthly subscription.', textDesc: 'Unworn fashion treasures pilled in your wardrobe? We are here to sell your items fast and help you to make some extra money! Extend the life of luxury contributing in more sustainable future.', header: 'Sell and earn', buttonText: 'Sell with us', price: 'The lowest commission rates' },
        { link: this.localizedRoute('marketplace'), image: '/assets/oferta-sprzedaje.jpg', alt: 'icon4', textMobile: "Pick 2 garments to try-on at home and after 24 hours return what you don't want to rent.", textDesc: 'Sneak into the world’s best pre-loved luxury wardrobes. Buy designer pieces up to 70% off the retail price. All the products are quality controlled and authenticated by our experts.', header: 'Buy', buttonText: 'Browse collection', price: 'Show me treasures' }
      ]
    },
    getClothesUrl () {
      return this.getMenuCategories.find(x => x.id === config.server.categoryIdsMap.mainCategoryId).url_path
    }
  },
  methods: {
    setMuted (currentPage) {
      if (this.isMainPage) {
        this.$emit('page-changed', { currentPage, perPage: this.$refs.carouselHomepage && this.$refs.carouselHomepage.perPage, type: this.list })
      }
      this.currentPage = currentPage
    }
  }
}
</script>

<style lang="scss" scoped>
  .abonament-info {
    display: flex;
    justify-content: center;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
        display: none;
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: start;
      margin-top: 0;
      margin-left: 1px;
    }
  }
  .abonament-info::-webkit-scrollbar {
    display: none;
  }
  .container {
    @media (min-width: 1200px){
      width: 1306px;
    }
  }
  .abonament-info-card {
    margin-top: 10px;
    &:first-child {
      padding-right: 60px;
    }
    &:nth-child(2) {
      padding-left: 34px;
      padding-right: 34px;
    }
    &:last-child {
      padding-left: 60px;
    }
    @media (max-width: 1200px) {
      &:first-child {
        padding-right: 10px;
      }
      &:nth-child(2) {
        padding-left: 9px;
        padding-right: 9px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
  .order-mobile {
    display: flex;
    flex-direction: column;
  }
  .mobile-header {
      @media (max-width: 767px) {
      font-size: 28px;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
</style>

<style lang="scss">
.our-offer-carousel {
  .VueCarousel-wrapper {
    padding-bottom: 20px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      padding: 0;
    }
  }
  .VueCarousel-inner--center {
    justify-content: unset;
  }
  .VueCarousel-slide {
    overflow: auto;
  }
  .VueCarousel-pagination--bottom-overlay {
    bottom: -15px !important;
    z-index: 1;
    @media (max-width: 767px) {
      bottom: -35px !important;
    }
  }
  .VueCarousel-navigation-prev {
    left: initial !important;
    right: 53px !important;
    @media (max-width: 767px) {
      left: initial !important;
      right: 53px !important;
    }
  }
  .VueCarousel-navigation-next {
    right: 0 !important;
    @media (max-width: 767px) {
      right: 0 !important;
    }
  }
  .VueCarousel-dot-container {
    margin: 0 !important;
    .VueCarousel-dot {
      margin: 0 !important;
    }
  }
  .VueCarousel-navigation-button {
    top: -57px !important;
    background-color: transparent !important;
    width: 45px !important;
    height: 45px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 16px !important;
    color: gray !important;
    z-index: 1 !important;
    @media (max-width: 767px) {
      background-color: white !important;
      top: -57px !important;
      width: 45px !important;
      height: 45px !important;
    }
  }
  .VueCarousel-navigation--disabled {
    display: none !important;
  }
  .VueCarousel-navigation-prev {
    transform: none !important;
    i {
      font-size: 45px;
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
        font-size: 35px;
        font-weight: 800;
      }
    }
  }
  .VueCarousel-navigation-next {
    transform: none !important;
    i {
      font-size: 45px;
      padding-left: 4px;
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
        font-size: 35px;
        font-weight: 800;
      }
    }
  }
}
</style>
