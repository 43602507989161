<template>
  <div class="relative mt25">
    <div class="image-container">
      <img class="hidden-xs image" v-lazy="'/assets/homepage-dlaczego-eg.jpg'" :alt="'Why E-Garderobe image'">
      <img class="visible-xs image" v-lazy="'/assets/girl-going-mobile.jpg'" :alt="'Why E-Garderobe image'">
    </div>
    <div class="absolute flex center-xs text-block">
      <div>
        <h2 class="center-xs text-block-header">
          {{ $t("Why E-Garderobe?") }}
        </h2>
        <p class=" center-xs paragraph">
          {{ $t("We are the only exclusive online platform where you can rent, sell or buy designer items. Extend the life of luxury contributing to a more sustainable future.") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageBlock'
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-white: color(white);

  .image-container {
    position: relative;
    height: 0;
    padding-top: 37.1%;
    @media(max-width: 767px) {
      padding-top: 119.4%;
    }
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
  .text-block {
    top: 50%;
    left: 30%;
    transform: translate(-30%, -50%);
    width: 400px;
    padding: 50px;
    background-color: $color-white;
    font-weight: 400;
    font-size: 16px;
    @media (max-width: 1025px) {
      width: 366px;
      padding: 40px;
      left: 20%;
      transform: translate(-20%, -50%);
    }
    @media (max-width: 992px) {
      width: 366px;
      padding: 30px;
      left: 10%;
      transform: translate(-10%, -50%);
      font-size: 14px;
    }
    @media (max-width: 767px) {
      width: unset;
      padding: 80px;
      top: 50%;
      left: 25px;
      right: 25px;
      bottom: 25px;
      transform: unset
    }
    @media (max-width: 560px) {
      padding: 40px;
      font-size: 12px;
    }
    @media (max-width: 500px) {
      padding: 30px;
      font-size: 11px;
    }
    @media (max-width: 400px) {
      padding: 20px;
      font-size: 11px;
      top: unset;
    }
  }
  .text-block-header {
    margin-top: 0;
    @media (max-width: 768px) {
      font-size: 28px;
      margin-bottom: 10px;
    }
    @media (max-width: 400px) {
      font-size: 23px;
    }
  }
  .paragraph {
    letter-spacing: 0.8px;
    line-height: 25px;
    margin-bottom: 0;
    @media (max-width: 500px) {
      line-height: 20px;
    }
  }
</style>
