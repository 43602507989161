<template>
  <div class="container px0">
    <div class="media-gallery-carousel1">
      <div class="container px0">
        <no-ssr>
          <carousel
            v-bind="sliderConfig"
            @pageChange="setMuted"
            :infinite="true"
            @transitionStart="pushEvent"
          >
            <slide
              v-for="(banner, index) in validBanners"
              :key="index"
              aria-hidden="true"
              ref="main-banner"
              :value="banner"
              @slideclick="handleSlideClick(banner)"
            >
              <div class="row m0">
                <!-- click="handleSlideClick(validBanners[index])" -->
                <div class="col-md-12 p0 image">
                  <img v-lazy="getThumbnailPath(banner.urlDesktop, banner.urlMobile)" :alt="banner.alt ? banner.alt : 'banner'" width="1565px" height="435px">
                </div>
              </div>
            </slide>
          </carousel>
          <div slot="placeholder" class="col-md-12 bg-cl-secondary-gray image" />
        </no-ssr>
      </div>
    </div>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'

export default {
  name: 'MainBannersCarousel',
  components: {
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR
  },
  props: {
    validBanners: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      sliderConfig: {
        perPage: 1,
        paginationEnabled: true,
        autoplayTimeout: 6000,
        paginationColor: '#C7C7C7',
        paginationActiveColor: '#000000',
        paginationPosition: 'bottom-overlay',
        infiniteLoop: true,
        centerMode: true,
        touchDrag: true,
        scrollPerPage: true,
        autoplay: true,
        loop: true
      },
      currentPage: 0,
      isLoad: false,
      pagesArr: []
    }
  },
  methods: {
    pushEvent () {
      if (!this.pagesArr.includes(this.currentPage)) {
        let bannerData = this.$refs['main-banner'][this.currentPage].$attrs && this.$refs['main-banner'][this.currentPage].$attrs.value
        bannerData['alt'] = 'main-banner'
        bannerData['image'] = bannerData.urlDesktop
        this.$store.commit('ui/VIEW_BANNER', bannerData)
        this.pagesArr.push(this.currentPage)
      }
    },
    getThumbnailPath (urlDesktop, urlMobile) {
      if (!this.$device.isMobile) {
        return _thumbnailHelper(urlDesktop, 1024, 635, 'banners')
      } else {
        return _thumbnailHelper(urlMobile, 767, 445, 'banners')
      }
    },
    handleSlideClick (banner) {
      this.$router.push(this.localizedRoute('/' + banner.link))
      banner['alt'] = 'main-banner'
      banner['image'] = banner.urlDesktop
      this.$store.commit('ui/CLICK_BANNER', banner)
    },
    setMuted (currentPage) {
      this.currentPage = currentPage
    }
  }
}
</script>

<style lang="scss">
.media-gallery-carousel1 {
  cursor: pointer;
  .VueCarousel-pagination--bottom-overlay {
    // @media (max-width: 767px) {
    //   display: none;
    // }
    position: initial !important;
    // bottom: -20px !important;
    // left: calc(50% - 20px);
  }
  .VueCarousel-pagination {
    width: auto !important;
    .VueCarousel-dot-container {
      margin: 0 !important;
      .VueCarousel-dot {
        outline: none !important;
        padding: 0 5px !important;
        margin: 0 !important;
        height: 10px !important;
        width: 10px !important;
        flex-grow: 1;
        flex-basis: 0;
        // background-color: #C7C7C7 !important;
      }
    }
  }
  .VueCarousel-wrapper {
    position: relative;
    height: 0;
    padding-top: 42.9%;
    @media (max-width: 767px) {
      padding-top: 97.9%;
    }
  }
  .VueCarousel-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .VueCarousel-slide {
    max-width: 100%;
  }
}
</style>

<style lang="scss" scoped>
.placeholder-cms {
  @media (max-width: 767px) {
    height: 207px;
    background-color: #cccccc;
  }
   @media (max-width: 400px) {
    height: 265px;
    background-color: #cccccc;
  }
  @media (max-width: 338px) {
    height: 267px;
    background-color: #cccccc;
  }
}
.media-gallery-carousel1 {
  @media (max-width: 1500px) {
    padding-top: calc(100% * 1/2.33);
  }
  @media (max-width: 767px) {
    padding-top: calc(100% * 1.06);
  }
  padding-top: calc(100% * 1/2.79);
  position: relative;
  .container {
    width: 1304px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    @media (max-width: 1304px) {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    background-color: initial;
    .container {
      padding-top: 0;
    }
  }
  .image {
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
}
.offer {
  &__button {
    @media (max-width: 767px) {
      padding: 10px 50px;
      margin-bottom: 30px;
    }
    font-size: 16px !important;
    padding: 15px 50px;
    display: inline-block;
    min-width: auto;
  }
  &__image {
    @media (max-width: 767px) {
      max-height: 190px;
      width: auto;
    }
  }
  &__info {
    @media (max-width: 767px) {
      margin-left: 0;
      margin-top: 20px;
      text-align: left;
      h2 {
        text-align: left;
      }
      p {
        font-size: 16px;
        text-align: left;
      }
    }
  }
}

</style>
