<template>
  <div class="home-products-carousel">
    <no-ssr>
      <carousel
        v-bind="sliderConfigMainPage"
        @pageChange="setMuted"
        :infinite="true"
        ref="carouselHomepage"
      >
        <slide
          v-for="(product, index) in products"
          :key="index"
        >
          <product-tile
            columns="on"
            class="collection-product"
            :product="product"
            :link-active="false"
            :product-related="true"
            :position="index"
            :list="list"
            @product-slide-click="handleSlideClick(index)"
          />
        </slide>
      </carousel>
      <div slot="placeholder" class="row m0">
        <div class="col-xs-2 mr10 placeholder bg-cl-secondary-gray" />
        <div class="col-xs-2 mr10 hidden-xs placeholder bg-cl-secondary-gray" />
        <div class="col-xs-2 mr10 hidden-xs placeholder bg-cl-secondary-gray" />
        <div class="col-xs-2 mr10 hidden-xs placeholder bg-cl-secondary-gray" />
      </div>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { onlineHelper } from '@vue-storefront/core/helpers'
import ProductTile from 'theme/components/core/ProductTile'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'HomeProductsCarousel',
  components: {
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR,
    ProductTile
  },
  props: {
    list: {
      type: String,
      required: false,
      default: 'carousel'
    },
    products: {
      type: null,
      required: true
    },
    isMainPage: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      reguired: true,
      default: ''
    },
    link: {
      type: String,
      reguired: true,
      default: '/'
    },
    background: {
      type: String,
      reguired: false,
      default: 'FF580E'
    }
  },
  data () {
    return {
      sliderConfigMainPage: {
        perPageCustom: [[1, 1.5], [320, 2], [768, 3], [992, 3], [1400, 4]],
        centerMode: true,
        touchDrag: true,
        minSwipeDistance: 150,
        paginationEnabled: true,
        paginationActiveColor: '#000000',
        paginationColor: '#CFCFCF',
        paginationPosition: 'bottom-overlay',
        navigationEnabled: true,
        navigationNextLabel: '<i class="icon-chevron-right" />',
        navigationPrevLabel: '<i class="icon-chevron-left" />'
      },
      currentPage: 0
    }
  },
  computed: {
    isOnline () {
      return onlineHelper.isOnline
    },
    productLink () {
      var t = {}
      for (var k = 0; k < this.products.length; k++) {
        t[k] = formatProductLink(this.products[k], currentStoreView().storeCode)
      }
      return t
    }
  },
  methods: {
    handleSlideClick (index) {
      this.$router.push(this.productLink[index])
    },
    setMuted (currentPage) {
      if (this.isMainPage) {
        this.$emit('page-changed', { currentPage, perPage: this.$refs.carouselHomepage && this.$refs.carouselHomepage.perPage, type: this.list })
      }
      this.currentPage = currentPage
    }
  }
}
</script>

<style lang="scss">
.home-products-carousel {
  .VueCarousel-wrapper {
    padding-bottom: 20px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      padding: 0;
    }
  }
  .VueCarousel-inner--center {
    justify-content: unset;
  }
  .VueCarousel-slide {
    overflow: auto;
  }
  .VueCarousel-pagination--bottom-overlay {
    bottom: -15px !important;
    @media (max-width: 767px) {
      bottom: -35px !important;
    }
  }
  .VueCarousel-navigation-prev {
    left: initial !important;
    right: 53px !important;
    @media (max-width: 767px) {
      left: initial !important;
      right: 53px !important;
    }
  }
  .VueCarousel-navigation-next {
    right: 0 !important;
    @media (max-width: 767px) {
      right: 0 !important;
    }
  }
  .VueCarousel-dot-container {
    margin: 0 !important;
    .VueCarousel-dot {
      margin: 0 !important;
    }
  }
  .VueCarousel-navigation-button {
    top: -57px !important;
    background-color: transparent !important;
    width: 45px !important;
    height: 45px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 16px !important;
    color: gray !important;
    z-index: 1 !important;
    @media (max-width: 767px) {
      background-color: white !important;
      top: -57px !important;
      width: 45px !important;
      height: 45px !important;
    }
  }
  .VueCarousel-navigation--disabled {
    display: none !important;
  }
  .VueCarousel-navigation-prev {
    transform: none !important;
    i {
      font-size: 45px;
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
        font-size: 35px;
        font-weight: 800;
      }
    }
  }
  .VueCarousel-navigation-next {
    transform: none !important;
    i {
      font-size: 45px;
      padding-left: 4px;
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
        font-size: 35px;
        font-weight: 800;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.visible-xs-and-tablet-carousel {
  @media only screen and (min-width:768px) {
    display: none;
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    display: block;
  }
}
.placeholder {
  margin-bottom: 40px;
  min-width: 228px;
  min-height: 452px;
  border-radius: 15px;
  height: 90%;
  width: 100%;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    min-width: 150px;
    min-height: 280px;
  }
}
.product {
  &.collection-product {
    padding-right: 0;
    width: auto;
    margin: 20px !important;
    @media (max-width: 767px) {
      margin: 0 !important;
    }
  }
}
.box {
  width: 100%;
  height: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
}
.inner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40%;
  margin-top: -10px;
  padding-bottom: 10px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    height: 35%;
  }
}
.sales {
  background-image: url("/assets/home/Nowosci.jpg");
}
.bestsellers {
  background-image: url("/assets/home/polecane.jpg");
}
.news {
  background-image: url("/assets/home/Oferty-Specjalne.jpg");
}
</style>
