<template>
  <div class="container relative product-home-listing pb50">
    <div class="row m0 offer-banner">
      <div class="section-tablet col-md-11 navigation-area">
        <h2 class="center-md center-xs mobile-header fs-big cl-black weight-500">{{ $t(title) }}</h2>
        <home-products-carousel :title="title" :link="link" :background="background" :is-main-page="true" :products="products" :list="title" />
      </div>
    </div>
    <router-link class="static-block__button_link" :to="link">
      <button-outline class="static-block__button" :title="$t(buttonText ? '' :'See the') + ' ' + $t(buttonText ? buttonText : title)" />
    </router-link>
  </div>
</template>

<script>
import HomeProductsCarousel from 'theme/components/core/blocks/Home/HomeProductsCarousel.vue'
import ButtonOutline from 'src/themes/theme-egarderobe/components/theme/ButtonOutline.vue'

export default {
  name: 'HomeProducts',
  components: {
    HomeProductsCarousel,
    ButtonOutline
  },
  props: {
    products: {
      type: null,
      required: true
    },
    title: {
      type: String,
      reguired: true,
      default: ''
    },
    link: {
      type: String,
      reguired: true,
      default: '/'
    },
    buttonText: {
      type: String,
      reguired: false,
      default: ''
    },
    background: {
      type: String,
      reguired: false,
      default: 'FF580E'
    }
  },
  methods: {
    imageUrl (background) {
      if (background === 'E2A205') {
        return '/assets/home/Oferty-Specjalne.jpg'
      } else if (background === '00412B') {
        return '/assets/home/polecane.jpg'
      } else {
        return '/assets/home/Nowosci.jpg'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-tablet {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.more-btn-home {
  @media (max-width: 1070px) {
    font-size: 14px;
  }
  @media (max-width: 970px) {
    font-size: 12px;
    padding: 5px 7px;
  }
}
.offer-banner {
  justify-content: center;
  @media (max-width: 767px) {
  }
}
.mobile-header {
  margin-top: 40px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 28px;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
.static-block__button_link {
  position: absolute;
  left: 50%;
  bottom: 0;
}
.static-block__button {
    background-color: black;
    color: white;
    transform: translateX(-50%);
    @media (max-width: 767px) {
      background: rgb(0, 0, 0);
      font-size: 11px;
      color: rgb(255, 255, 255);
    }
  }
.static-block__button:hover {
  background-color: white;
  color: black;
}
.product-home-listing {
  padding-bottom: 70px;
  @media (max-width: 767px) {
    padding-bottom: 90px;
  }
  .navigation-area {
    padding: 0 50px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
      padding: 0 8px;
    }
    @media (max-width: 767px) {
      padding: 0 8px;
    }
  }
  .box {
    width: 55%;
    height: 100%;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .inner-box {
    p {
      margin-top: 40%;
    }
    width: 45%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .inner-container {
    padding-top: calc(100% * 1/3);
    height: 0;
  }
}
</style>
