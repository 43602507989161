<template>
  <router-link :to="card.link">
    <div class="abonament-info-tabs">
      <div class="abonament-info-image-container">
        <img class="abonament-info-image" :src="card.image" :alt="card.alt">
      </div>
      <div class="tab-content">
        <div class="tabs-header">
          {{ $t(card.header) }}
        </div>
        <p class="tabs-paragraph">
          {{ $t(card.textDesc) }}
        </p>
        <p class="center-xs tabs-paragraph__bold">
          {{ $t(card.price) }}
        </p>
      </div>
      <p class="center-xs pt10 paragraph-underline">
        {{ $t(card.buttonText) }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .abonament-info-tabs {
    position: relative;
    padding-bottom: 25px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      min-height: 250px;
      margin-right: 10px;
    }
  }
  .abonament-info-image-container {
    position: relative;
    height: 0;
    padding-top: 96.8%;
  }
  .abonament-info-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
  .tab-content {
    height: 225px;
    @media (max-width: 1500px) {
      height: 245px;
    }
    @media (max-width: 1100px) {
      height: 235px;
    }
    @media (max-width: 992px) {
      height: 195px;
    }
    @media (max-width: 870px) {
      height: 185px;
    }
    @media (max-width: 630px) {
      height: 205px;
    }
    @media (max-width: 600px) {
      height: 220px;
    }
    @media (max-width: 540px) {
      height: 235px;
    }
    @media (max-width: 510px) {
      height: 253px;
    }
    @media (max-width: 450px) {
      height: 155px;
    }
    @media (max-width: 360px) {
      height: 170px;
    }
  }
  .tabs-header {
    color: black;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.48px;
    @media (max-width: 1200px) {
      font-size: 1.65vw;
      font-weight: 600;
    }
    @media (max-width: 768px) {
      font-size: 15px;
      font-family: 'Poppins';
      padding-left: 0px;
      text-align: center;
      text-transform: uppercase;;
    }
  }
  .tabs-paragraph {
    margin: 10px;
    font-size: 12px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    color: black;
    line-height: 2;
    @media (max-width: 1200px) {
      font-size: 1.05vw;
    }
    @media (max-width: 768px) {
      font-size: 11px;
      text-align: center;
      line-height: 1.5;
    }
  }
  .tabs-paragraph__bold {
    font-size: 14px;
    font-weight: 600;
    color: #333C39;
    @media (max-width: 1200px) {
      font-size: 1.05vw;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      text-align: center;
      line-height: 1.5;
    }
  }
  .paragraph-underline {
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.96px;
    font-weight: 600;
    @media (max-width: 1200px) {
      font-size: 1.05vw;
    }
    @media (max-width: 768px) {
      font-size: 11px;
      text-align: center;
      line-height: 1.5;
    }
  }
</style>
