<template>
  <div class="container flex  column instagram-press middle-sm">
    <div class="relative">
      <h2 class="center-md fs-big title1 cl-black weight-400">
        {{ $t('Follow us ') }}
      </h2>
      <p v-if="validBaners['instagram_followers']" class="svg-text">
        {{ validBaners['instagram_followers'].count }}
      </p>
      <img class="insta-svg" alt="instagram-svg" v-lazy="'/assets/instagram.svg'">
      <a aria-label="Instagram" rel="noreferrer nofollow" href="https://www.instagram.com/egarderobe/">
        <img class="image hidden-xs pb40" alt="instagram" v-lazy="'/assets/instapictures1.png'" width="1304" height="337">
      </a>
      <a aria-label="Instagram" rel="noreferrer nofollow" href="https://www.instagram.com/egarderobe/">
        <img class="image visible-xs pb30" alt="instagram" v-lazy="'/assets/instagram_banner_mobile.png'" width="391" height="394">
      </a>
      <a aria-label="Instagram" class="static-block__button" rel="noreferrer nofollow" href="https://www.instagram.com/egarderobe/">
        <button-outline class="hidden-xs" :title="$t('Get inspired')" />
      </a>
    </div>
    <h2 class="center-md pt45 title fs-big cl-black weight-400">
      {{ $t('As seen in') }}
    </h2>
    <div class="pr15 press pb10 flex">
      <img v-for="(item, indexPress) in validBaners['mainpage_press']" :key="indexPress" v-lazy="getThumbnailPath(item.image, 160, 55)" width="180" height="65" alt="image" class="logo-border m30 p5">
    </div>
    <p class="press-text">
      <router-link :to="localizedRoute('/prasa')">
        {{ $t('Read More') }}
      </router-link>
    </p>
  </div>
</template>

<script>
import ButtonOutline from 'src/themes/theme-egarderobe/components/theme/ButtonOutline.vue'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'

export default {
  name: 'InstagramAndPress',
  components: {
    ButtonOutline
  },
  data () {
    return {
    }
  },
  props: {
    ossasions: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getThumbnailPath (image, widht, height) {
      return _thumbnailHelper(image, widht, height, 'banners')
    }
  },
  computed: {
    validBaners () {
      return this.$store.state.cmsBlock.baners
    }
  }
}
</script>

<style lang="scss" scoped>
  .press{
    @media (max-width: 1304px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
    }
    @media (max-width: 767px) {
      align-items: center;
      justify-content: space-evenly;
    }
  }
  .press-text {
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: 600;
  }
 .static-block__button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;
    @media (max-width: 767px) {
      background: white;
      font-size: 11px;
      color: black;
    }
  }
  .instagram-press {
    width: 1304px;
    margin-bottom: 100px;
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 70px;
    }
  }
  .insta-svg {
    width: 35px;
    position: absolute;
    right: 0;
    top: 30px;
    @media (max-width: 767px) {
      width: 15px;
      text-align: center;
      right: 15px;
      top: 35px;
   }
  }
  .logo-border {
    @media (max-width: 767px) {
      width: 36%;
      height: 38%;
      margin: 15px;
    }
  }
  .image {
    object-fit: contain;
    @media (max-width: 1304px) {
      width: 100vw;
      width: 100%;
      height: auto;
    }
  }
  .svg-text {
    position: absolute;
    right: 45px;
    top: 35px;
    margin: 0;
    font-size: 24px;
    @media (max-width: 767px) {
     font-size: 14px;
     top: 32px;
     text-align: center;
   }
  }
  .title1 {
    margin-top: 0;
    margin-bottom: 40px;
   @media (max-width: 767px) {
     font-size: 28px;
     text-align: center;
   }
 }
  .title {
    margin-bottom: 0;
   @media (max-width: 767px) {
     margin-top: 0;
     padding-top: 0;
     font-size: 28px;
     text-align: center;
     padding-bottom: 10px;
   }
 }
</style>
