<template>
  <div id="home">
    <!-- Add WebSite rich snippet -->
    <div class="hidden" itemscope itemtype="http://schema.org/WebSite">
      <link itemprop="url" :href="hostName">
      <meta itemprop="name" content="name">
      <meta itemprop="alternateName" content="alternateName">
      <form itemprop="potentialAction" itemscope itemtype="http://schema.org/SearchAction">
        <meta itemprop="target" itemtype="http://schema.org/EntryPoint" :content="hostName + '/sklep?query={search_term_string}'">
        <input itemprop="query-input" required name="search_term_string">
        <input type="submit">
      </form>
    </div>
    <LazyHydrate when-idle>
      <MainBannersBlockCms :valid-banners="validBaners['mainbanner']" />
    </LazyHydrate>
    <our-offer-tabs />
    <HomeProducts v-if="getBestsellers && getBestsellers.length" :products="getBestsellers" :button-text="'Learn More'" class="main-page-carusel" :title="$t('Rent')" :link="localizedRoute(getMainCategory.url_path + '?sort=sales_count:desc')" :background="'5b5b5b'" />
    <image-block />
    <HomeProducts v-if="getNews && getNews.length" :products="getNews" :button-text="'Learn More'" class="main-page-carusel" :title="$t('Buy pre-owned')" :link="localizedRoute(getMarketplaceCategory.url_path + '?sort=id%3Adesc')" :background="'5b5b5b'" />
    <!-- <home-ossasions :ossasions="getOccasions" :link="getMenuCategories.find(x => x.id === 106).url_path" /> -->
    <featured-designers v-if="getDesignerCategory" :desiginers="getDesignerCategory.children_data" :link="getDesignerCategory.url_path" />
    <customer-service />
    <div id="a005707d-3983-4c73-b30d-8551fe01f291" class="mt10 mb20" />
    <instagram-and-press />
  </div>
</template>

<script>
import { isServer } from '@vue-storefront/core/helpers'
import LazyHydrate from 'vue-lazy-hydration'
// Theme local components
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import { mapGetters } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger'
import MainBannersBlockCms from 'theme/components/core/blocks/Home/MainBannersBlockCms'
import config from 'config'
import HomeProducts from 'theme/components/core/blocks/Home/HomeProducts.vue'
// import HomeOssasions from '../components/core/blocks/Home/HomeOssasions.vue'
import FeaturedDesigners from 'theme/components/core/blocks/Home/FeaturedDesigners.vue'
import InstagramAndPress from 'theme/components/core/blocks/Home/InstagramAndPress.vue'
// import CategoriesSection from 'theme/components/core/blocks/Home/CategoriesSection'
import CustomerService from '../components/core/blocks/Home/CustomerService.vue'
import ImageBlock from 'theme/components/core/blocks/Home/ImageBlock'
import OurOfferTabs from 'theme/components/core/blocks/Home/OurOfferTabs.vue'

export default {
  data () {
    return {
      loading: true
    }
  },
  components: {
    MainBannersBlockCms,
    LazyHydrate,
    HomeProducts,
    // CategoriesSection,
    // HomeOssasions,
    FeaturedDesigners,
    InstagramAndPress,
    CustomerService,
    ImageBlock,
    OurOfferTabs
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters('homepage', ['getPromoBlockCollection', 'getBestsellers', 'getNews', 'getSales']),
    ...mapGetters({
      getMenuCategories: 'category-next/getMenuCategories'
    }),
    validBaners () {
      return this.$store.state.cmsBlock.baners
    },
    getMainCategory () {
      return this.getMenuCategories.find(cat => cat.id === config.server.categoryIdsMap.mainCategoryId)
    },
    getDesignerCategory () {
      return this.getMenuCategories.find(x => x.id === config.server.categoryIdsMap.designerCategoryId)
    },
    getOccasionsCategory () {
      return this.getMenuCategories.find(x => x.id === config.server.categoryIdsMap.occasionCategotyId)
    },
    getMarketplaceCategory () {
      return this.getMenuCategories.find(cat => cat.id === config.server.categoryIdsMap.marketplaceCategoryId)
    },
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.some(
        field => ['homepage'].includes(field)
      )
    },
    isAuthElem () {
      return this.$store.state.ui.isAuthElem
    },
    hostName () {
      return config.server.hostName
    },
    productsByTypes () {
      // here is the product tabs definition
      return {
        'Bestseller': this.getBestsellers,
        'New': this.getNews,
        'Sale': this.getSales
      }
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  },
  mounted () {
    if (!this.isLoggedIn && localStorage.getItem('redirect')) this.$bus.$emit('modal-show', 'modal-signup')
  },
  watch: {
    isLoggedIn () {
      const redirectObj = localStorage.getItem('redirect')
      if (redirectObj) this.$router.push(redirectObj)
      localStorage.removeItem('redirect')
    },
    isAuthElem () {
      if (!this.isAuthElem) localStorage.removeItem('redirect')
    }
  },
  async asyncData ({ store, route, context }) { // this is for SSR purposes to prefetch data
    if (context) context.output.cacheTags.add(`home`)
    Logger.info('Calling asyncData in Home Page (core)')()

    await Promise.all([
      store.dispatch('homepage/loadSales'),
      store.dispatch('cmsBlock/list', { filterValues: null }),
      store.dispatch('homepage/loadNews'),
      store.dispatch('homepage/loadBestsellers')
    ])
    for (let type of Object.keys(store.state.cmsBlock.baners)) {
      if (Array.isArray(store.state.cmsBlock.baners[type])) {
        for (let baner of store.state.cmsBlock.baners[type]) {
          if (context && baner.id) context.output.cacheTags.add('CMSB' + baner.id)
        }
      } else {
        if (context && store.state.cmsBlock.baners[type] && store.state.cmsBlock.baners[type].id) context.output.cacheTags.add('CMSB' + store.state.cmsBlock.baners[type].id)
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) next() // SSR no need to invoke SW caching here
    else if (!from.name) { // Loading products to cache on SSR render
      next(async vm =>
        Promise.all([
          vm.$store.commit('ui/routeChanged', { to: to, from: from }),
          vm.$store.dispatch('cmsBlock/list', { filterValues: null }),
          vm.$store.dispatch('homepage/loadSales'),
          vm.$store.dispatch('homepage/loadNews'),
          vm.$store.dispatch('homepage/loadBestsellers')
        ]).then(res => {
          vm.loading = false
        })
      )
    } else {
      next(async vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  },
  metaInfo () {
    let script = [
      {
        defer: 'defer',
        src: 'https://trustmate.io/api/widget/a005707d-3983-4c73-b30d-8551fe01f291/script'
      }
    ]
    let link = []
    let store_view_urls = {
      'store_1': 'pl',
      'store_2': 'de',
      'store_3': 'en'
    }
    if (store_view_urls && Object.keys(store_view_urls).length) {
      for (let storecode of Object.keys(store_view_urls)) {
        const storeId = storecode && storecode.split('_')[1]
        if (storeId) {
          let locale, url
          config.storeViews.mapStoreUrlsFor.forEach(storeCode => {
            if (config.storeViews[storeCode] && config.storeViews[storeCode].storeId === Number(storeId)) {
              locale = config.storeViews[storeCode].i18n.defaultLocale
              url = config.storeViews[storeCode].url
            }
          })
          if (url && locale !== config.storeViews[this.$store.state.storeView.storeCode].i18n.defaultLocale) {
            link.push({
              rel: 'alternate',
              hreflang: locale === 'en-US' ? 'x-default' : locale,
              href: config.server.hostName + url + '/'
            })
          }
        }
      }
    }
    return {
      script,
      htmlAttrs: { lang: config.storeViews[this.$store.state.storeView.storeCode].i18n.defaultLocale },
      link,
      meta: [
        {
          vmid: 'og:locale',
          property: 'og:locale',
          content: this.$store.state.storeView.storeCode ? config.storeViews[this.$store.state.storeView.storeCode].i18n.defaultLocale : ''
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .new-collection {
    @media (max-width: 767px) {
      padding-top: 0;
      margin-bottom: 0;
    }
    h2 {
      @media (max-width: 767px) {
        padding: 10px 0;
        font-size: 16px;
      }
    }
  }
  .abobanebt-order {
    @media (max-width: 767px) {
      order: 2;
    }
  }
  .category-order {
    @media (max-width: 767px) {
      order: 1;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .logo-box {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    padding: 10px 0;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
</style>
